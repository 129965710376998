@media print {
  .break-page {
    page-break-after: always;
  }
}

@media print {
  div.break-page {
    page-break-inside: avoid;
    margin           : 0mm
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

@media print {
  .ant-table table {
    border-collapse: unset;
    border-spacing: 0;
  }
}