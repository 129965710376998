.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    cursor : pointer;
    min-height: 30px;
}

.editable-row:hover .editable-cell-value-wrap {
    padding      : 4px 4px;
    border       : 1px solid #d9d9d9;
    border-radius: 4px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}